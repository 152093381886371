import axios from 'axios';

const API_URLA = process.env.VUE_APP_APIA+'game/';

class GameService {
  getGames() {
    return axios.post(API_URLA + 'getGames/');
  }
  getGame(id) {
    return axios.post(API_URLA + 'getGame/', {"id": id});
  }
  saveGame(data) {
    return axios.post(API_URLA + 'saveGame/', data);
  }
  getGamesByBill(bill_id) {
    return axios.post(API_URLA + 'getGamesByBill/', {"bill_id": bill_id});
  }
  saveGamesByBill(bill_id, games, games_display_in_cashier, games_display_in_admin) {
    return axios.post(API_URLA + 'saveGamesByBill/', {"bill_id": bill_id, "games": games, "games_display_in_cashier": games_display_in_cashier, "games_display_in_admin": games_display_in_admin});
  }
  setActiveStatus(id, status) {
    return axios.post(API_URLA + 'setActiveStatus/', {"id": id, "status": status});
  }
  getGamesByCurr(curr, bill_id) {
    return axios.post(API_URLA + 'getGamesByCurr/', {"curr": curr, "bill_id": bill_id});
  }
  getGamesListByBill(bill_id) {
    return axios.post(API_URLA + 'getGamesListByBill/', {"bill_id": bill_id});
  }
  getGameTypes() {
    return axios.post(API_URLA + 'getGameTypes/');
  }
  getGameVersions() {
    return axios.post(API_URLA + 'getGameVersions/');
  }
  getGameBytypeData() {
    return axios.post(API_URLA + 'getGameBytypeData/');
  }
}

export default new GameService();
