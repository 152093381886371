<template>
  <p></p>
  <div v-if="!SelBillsOneCur && !diferentCur" class="container-fluid">{{ $t('wizards.changegame.changegame.pleaseSelectAt') }}</div>
  <div v-if="diferentCur" class="container-fluid">{{ $t('wizards.changegame.changegame.diferentCur') }}</div>
  <div v-if="SelBillsOneCur && !diferentCur" class="container-fluid">
    <label><input type="radio" name="type" v-model="typew" value="all"> {{ $t('wizards.changegame.changegame.redefiningTheWholeList') }}</label><br>
    <label><input type="radio" name="type" v-model="typew" value="add"> {{ $t('wizards.changegame.changegame.addingAGame') }}</label><br>
    <label><input type="radio" name="type" v-model="typew" value="del"> {{ $t('wizards.changegame.changegame.turningOffTheGame') }}</label><br>

    <vue-good-table v-if="typew=='all'" ref="table"
        :columns="columns"
        :rows="games_list"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
      compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <input v-if="props.column.field == 'isselect'" name="sel_games" type="checkbox" :value="props.row.id" v-model="sel_games">
      </template>
    </vue-good-table>

    <Select2 v-if="typew=='add' || typew=='del'" v-model="sel_game" :options="games_list" :settings="{ width: '100%' }" />

    <button v-if="typew!=''" @click="apply" class="btn btn-sm btn-success m-1">{{ $t('app.public.apply') }}</button>
  </div>
</template>

<script setup>
import WizardsService from "../../../services/wizards.service";
import GameService from "../../../services/game.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import {computed, inject, onMounted, ref, watch, watchEffect} from "vue";

const $t = inject('$t')

const selBillsInfo = inject('selBillsInfo')
const selOneBillInfo = inject('selOneBillInfo')

const typew = ref('')
const sel_games = ref([])
const sel_game = ref('')

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const selOneBill = inject('SelOneBill')
const SelBills = inject('SelBills')
const SelBillsOneCur = ref(false)
const diferentCur = ref(false)

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
  {
    label: $t('gamebill.gamesbill.id'),
    field: 'id',
    filterOptions: {enabled: true},
    width: '100px',
  },
  {
    label: $t('gamebill.gamesbill.gameName'),
    field: 'text',
    filterOptions: {enabled: true},
    width: '250px',
  },
  {
    label: $t('gamebill.gamesbill.linked'),
    field: 'isselect',
    filterOptions: {enabled: true},
    width: '270px',
  }
])
const games_list = ref([])

function getGamesByCurr(curr, bill_id)
{
  GameService.getGamesByCurr(curr, bill_id).then(
    (response) => {
      games_list.value = response.data;
    },
    (error) => {
      set_result(error.response.status, error)
    }
  )
}

function veryfyCur()
{
  SelBillsOneCur.value=false
  diferentCur.value=false

  let prevCUR=''
  if(cntSelBill.value==1) {
    SelBillsOneCur.value=true
    let cur=selOneBillInfo.value
    prevCUR=cur['currency']
  }

  if(cntSelBill.value>1) {
    for(let key in selBillsInfo.value) {
      let cur=selBillsInfo.value[key]
      if(prevCUR!='' && prevCUR!=cur['currency']) {
        diferentCur.value=true
        return false
      }

      prevCUR=cur['currency']
    }
  }

  if(prevCUR!='') {
    SelBillsOneCur.value=true
    getGamesByCurr(prevCUR, selOneBill.value)
  }
}
watchEffect(veryfyCur)

function gamesBillsSave()
{
  WizardsService.gamesBillsChange(SelBills.value, sel_games.value).then(
      () => {
        set_result()
        typew.value=''
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function gameBillsAdd()
{
  if(sel_game.value!='') {
    WizardsService.gameBillsAdd(SelBills.value, sel_game.value).then(
      () => {
        set_result()
        typew.value=''
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function gameBillsDel()
{
  if(sel_game.value!='') {
    WizardsService.gameBillsDel(SelBills.value, sel_game.value).then(
      () => {
        set_result()
        typew.value = ''
      },
      (error) => {
        set_result(error.response.status, error)
      }
    )
  }
}

function apply()
{
  if(typew.value=='all') {
    gamesBillsSave()
  }

  if(typew.value=='add') {
    gameBillsAdd()
  }

  if(typew.value=='del') {
    gameBillsDel()
  }
}
</script>

<style scoped>
</style>